<template>
  <div class="affiliates-users-pages-content">
    <a-row class="align-items-center mb-4" style="margin: -30px; padding: 22px 30px; background: #fff;" v-if="codeInfo">
        <a-col :span="18">
            <h3 class="mb-0" style="font-size: 20px;">{{codeInfo.code}}</h3>
        </a-col>
        <a-col :span="6" class="text-right" style="display: flex; justify-content: flex-end;">
          <a-button class="ml-2" @click="openAddLoginModal()">
              <a-icon type="plus" /> Add
          </a-button>
          <a-button class="ml-2" @click="openJson()">
              <a-icon type="file-done" /> Track
          </a-button>
          <a-button type="primary" class="ml-2" @click="tableUpdate()">
              <a-icon type="redo" /> Update
          </a-button>
        </a-col>
    </a-row>
    <a-card v-if="codeInfo">
      <a-table
            :columns="columns"
            :data-source="users"
            :rowKey="(record, index) => index"
            :loading="tableLoading"
            :pagination="pagination"
            @change="tableChange"
            :customRow="customRow"
          >
           <template slot="deposit" slot-scope="text, record">
             <div class="row-deposit">
                <span>{{record.deposit}}</span>/<span>{{codeInfo.limit}} </span>
                <a-icon :class="checkLimitDone(record)" v-if="record.deposit >= codeInfo.limit" type="check-circle" color="#52c41a" />
             </div>
           </template>
            <template slot="actions" slot-scope="text, record">
              <div class="row-operations">
                <span>
                  <a-popconfirm
                    title="Sure to delete this code with linked users?"
                    @confirm="() => deleteUser(record.login)"
                  >
                    <a class="font-size-18 text-danger delete-icon"  href="javascript:;"><a-icon type="delete" /></a>
                  </a-popconfirm>
                </span>
              </div>
          </template>
      </a-table>
    </a-card>

    <add-login-modal
      ref="add-login-modal"
      :codeInfo="codeInfo"
      @update="tableUpdate"
      @cancel="addLoginModalHandleCancel"
    />
  </div>
</template>

<script>
/* eslint-disable */
import apiClient from '@/services/axios'

export default {
  components: {
    'add-login-modal': () => import('./AddLoginModal.vue'),
  },
  props: [ 'code' ],
  data: () => ({
    users: [],
    codeInfo: null,
    pagination: {
      pageSize: 7,
      current: 1,
      total: 0,
    },
    tableLoading: false,
    columns: [
      {
        title: 'id',
        dataIndex: 'id',
        width: '10%',
      },
      {
        title: 'login',
        dataIndex: 'login',
        width: '25%',
      },
      {
        title: 'deposit',
        dataIndex: 'deposit',
        width: '25%',
        scopedSlots: { customRender: 'deposit' },
      },
      {
        title: 'created at',
        dataIndex: 'created_at',
        width: '25%',
      },
      {
        title: 'Actions',
        width: '15%',
        scopedSlots: { customRender: 'actions' },
      },
    ],
  }),
  async created() {
    await this.fetchCodeInfo();
    await this.fetchUsers();
  },
  computed: {},
  methods: {
    openJson() {
        window.open(`${apiClient.defaults.baseURL}/external/affiliates/fulfilled-users?id=${this.codeInfo.id}`, '_blank');
    },
    checkLimitDone(record) {
      if (record.deposit >= this.codeInfo.limit) {
        return "green-color";
      }
    },
    async tableUpdate() {
      try {
        this.tableLoading = true
        await this.fetchUsers()
      } catch (e) {
        console.error(e)
      } finally {
        this.tableLoading = false
      }
    },
    openAddLoginModal() {
      this.$nextTick(() => {
        this.$refs['add-login-modal'].showModal()
      })
    },
    async deleteUser(login) {
      try {
        this.tableLoading = true
        await apiClient.get(`admin/affiliates/remove-user?login=${login}`)
        this.$notification.success({
          message: 'Success',
          description: 'Login deleted successfully',
          duration: 2,
        })
        this.tableUpdate()
      } catch (e) {
        this.$notification.error({
          message: 'Error',
          description: e.message,
        })
      } finally {
        this.tableLoading = false
      }
    },
    addLoginModalHandleCancel() {
      console.lgo("addLoginModalHandleCancel");
    },
    tableChange(e) {
      this.pagination = e
      this.tableUpdate()
    },
    async fetchCodeInfo() {
      const { data } = await apiClient.get(`admin/affiliates/${this.code}`)
      this.codeInfo = data.data;
    },
    async fetchUsers() {
      const { current, pageSize } = this.pagination
      const { data } = await apiClient.get(`admin/affiliates/${this.code}/users?page=${current}&perPage=${pageSize}`)

      data.data.map((element) => {
        element.created_at = this.$moment(element.created_at).format('DD.MM.YYYY HH:mm:ss');
        return element;
      });

      this.users = data.data
      this.pagination.total = data.meta.total
    },
    customRow(event) {
      const _this = this;

      return {
        on: {
          click: (e) => {
            if (e.target.closest('.delete-icon')) {
              return false;
            }
          },
        },
      }
    },
  }
}
</script>

<style lang="scss">
  .affiliates-users-pages-content {
    .green-color {
      color: #52c41a;
    }
  }
</style>
